var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-2"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'Home'}}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Services")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"card"},[(_vm.is_loading)?_c('div',{staticClass:"overlay"},[_c('i',{staticClass:"fas fa-2x fa-sync-alt fa-spin"})]):_vm._e(),_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Detail")]),_c('div',{staticClass:"card-tools"},[_c('router-link',{staticClass:"btn btn-info",attrs:{"to":{name: 'Services'}}},[_c('i',{staticClass:"fas fa-list"}),_vm._v(" List ")])],1)]),_c('div',{staticClass:"card-body table-responsive p-0"},[_c('table',{staticClass:"table table-striped"},[_c('tbody',[_c('tr',[_vm._m(1),_c('td',[_vm._v(_vm._s(_vm.item.name))])]),_c('tr',[_vm._m(2),_c('td',[_vm._v(_vm._s(_vm.item.display_name))])]),_c('tr',[_vm._m(3),_c('td',[_vm._v(_vm._s((_vm.item.is_wallet) ? 'Yes' : 'No'))])]),_c('tr',[_vm._m(4),_c('td',[(_vm.item.service_logo)?_c('img',{staticClass:"img-thumbnail",attrs:{"src":_vm.service_logo,"alt":"service_logo"}}):_vm._e()])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(_vm._s(!_vm._.isEmpty(_vm.item.created_at) ? _vm.item.created_at : ''))])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(_vm._s(!_vm._.isEmpty(_vm.item.updated_at) ? _vm.item.updated_at : ''))])])])])]),_c('div',{staticClass:"card-footer"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h1',[_vm._v("Services")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Display Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Is Wallet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Service Logo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Created At")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Updated At")])])
}]

export { render, staticRenderFns }